import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Image from 'mui-image'
import Button from '@mui/material/Button';
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneIcon from '@mui/icons-material/Phone';

import TopTab from '../TopTab';
import { red } from '@mui/material/colors';

export default function Lodging() {
    const colorPrimary = '#617A55';
    const colorSecondary = '#A4D0A4';
    const colorTertiary = '#F7E1AE';
    const colorQuaternary = '#FFF8D6';
    const imageLoadDuration = 600;

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    // const svgIcon = (
    //     <Icon>
    //       <img alt="edit" src="/content/facebook.webp" />
    //     </Icon>
    //   );


  return (
    <Box>
        <Paper
            bgColor={colorTertiary}
            sx={{padding:2, margin:3}}>

            <Typography variant="h5" color="text.secondary" gutterBottom
                sx={{paddingBottom:2, textAlign:'left'}}>
                Book Now:
            </Typography>
            <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                >
                <Button variant="outlined" 
                startIcon={<FacebookIcon />}
                href="https://www.facebook.com/rgcamping" 
                target="_blank">
                    Facebook Messenger
                </Button>
            </Box>
            <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                >
                <Button variant="outlined" 
                startIcon={<PhoneIcon />}
                href="Tel: +886 963 517 380" 
                target="_blank">
                    Phone: +886 963 517 380
                </Button>
            </Box>

        </Paper>

        <Paper
            sx={{padding:2, margin:3, bgcolor:colorTertiary}}>

            <Typography variant="h5" color="text.secondary" gutterBottom
                sx={{textAlign:'left'}}>
                Options:
            </Typography>


            <Paper
                sx={{padding:2, margin:3, bgcolor:colorQuaternary}}
            >
                
                <Image
                    bgColor={colorQuaternary}
                    height='40vh' 
                    fit='cover'
                    src={"/content/huts.jpeg"}
                    duration={imageLoadDuration}
                ></Image>
                <Typography variant="h6" color="text.secondary" gutterBottom
                    sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                    Hut - NT$700 per person
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom
                    sx={{ textAlign:'left'}}>
                    Comfy mattress, electricity, bedside fan, hot showers, and an outdoor kitchen are all included.
                </Typography>
            </Paper>

            <Paper
                sx={{padding:2, margin:3, bgcolor:colorQuaternary}}
            >
                <Image
                    bgColor={colorQuaternary}
                    height='40vh' 
                    fit='cover'
                    src={"/content/tents.jpeg"}
                    duration={imageLoadDuration}
                ></Image>
                <Typography variant="h6" color="text.secondary" gutterBottom
                    sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                    Tent - NT$500 per person
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom
                    sx={{textAlign:'left'}}>
                    Bring your own tent. Hot showers, electricity, outdoor kitchen, and bar access are all included.
                </Typography>
            </Paper>

            <Paper
                sx={{padding:2, margin:3, bgcolor:colorQuaternary}}
            >
                <Image
                    bgColor={colorQuaternary}
                    height='40vh' 
                    fit='cover'
                    src={"/content/busroom.png"}
                    duration={imageLoadDuration}
                ></Image>
                <Typography variant="h6" color="text.secondary" gutterBottom
                    sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                    Camper - NT$800 per person
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom
                    sx={{textAlign:'left'}}>
                    Comfy mattress, electricity, AC, hot showers, and an outdoor kitchen are all included.
                </Typography>
            </Paper>
        </Paper>
    </Box>
  );
}