import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Image from 'mui-image'

import TopTab from '../TopTab';

export default function TheAnimals() {
    const colorPrimary = '#617A55';
    const colorSecondary = '#A4D0A4';
    const colorTertiary = '#F7E1AE';
    const colorQuaternary = '#FFF8D6';
    const imageLoadDuration = 600;

    const theme = useTheme();
    const [value, setValue] = React.useState(0);


    return (
        <Box>
            <Paper sx={{ padding: 2, paddingBottom: 2, bgcolor: colorTertiary, textAlign: 'left' }}>
                <Typography variant="h5" color="text.secondary" gutterBottom
                    sx={{ textAlign: 'left', paddingBottom: 2 }}>
                    The Animals:
                </Typography>
                <Paper
                    sx={{ padding: 2, margin: 3, bgcolor: colorQuaternary }}
                >
                    <Image
                        bgColor={colorQuaternary}
                        height='60vh'
                        fit='cover'
                        src={"/content/Dog1.jpeg"}
                        duration={imageLoadDuration}
                    ></Image>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{ paddingTop: 2, paddingBottom: 2, textAlign: 'left' }}>
                        Daq likes to come right up to new people and put her head against them until they pet her.
                    </Typography>
                </Paper>
                <Paper
                    sx={{ padding: 2, margin: 3, bgcolor: colorQuaternary }}
                >
                    <Image
                        bgColor={colorQuaternary}
                        height='60vh'
                        fit='cover'
                        src={"/content/Dog2.jpeg"}
                        duration={imageLoadDuration}
                    ></Image>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{ paddingTop: 2, paddingBottom: 2, textAlign: 'left' }}>
                        Maodou is a rescued stray. He's a little skittish, but he loves people. He may stand guard for you if you stay up late in the common area.
                    </Typography>
                </Paper>

                <Paper
                    sx={{ padding: 2, margin: 3, bgcolor: colorQuaternary }}
                >
                    <Image
                        bgColor={colorQuaternary}
                        height='60vh'
                        fit='cover'
                        src={"/content/chicken.jpeg"}
                        duration={imageLoadDuration}
                    ></Image>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{ paddingTop: 2, paddingBottom: 2, textAlign: 'left' }}>
                        The chickens do the hard job of eating all the bugs and snakes, keeping the campground safe. They will also follow you around given half a chance. You may find eggs in strange places, as some haven't mastered the idea of a nest and prefer leaving their eggs wherever.
                    </Typography>
                </Paper>
            </Paper>
        </Box>
    );
}