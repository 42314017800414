import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Image from 'mui-image'

import TopTab from '../TopTab';

export default function TheArea() {
    const colorPrimary = '#617A55';
    const colorSecondary = '#A4D0A4';
    const colorTertiary = '#F7E1AE';
    const colorQuaternary = '#FFF8D6';
    const imageLoadDuration = 600;

    const theme = useTheme();
    const [value, setValue] = React.useState(0);


  return (
    <Box>
        <Paper sx={{padding: 2, paddingBottom: 5, bgcolor:colorTertiary, textAlign:'left'}}>
                <Typography variant="h5" color="text.secondary" gutterBottom
                    sx={{textAlign:'left', paddingBottom: 2}}>
                    Things to do in Kenting, Taiwan:
                </Typography>
        <Image
            bgColor={colorQuaternary}
            height='60vh' 
            fit='cover'
            src={"/content/KentingMapSTANDIN.jpeg"}
            duration={imageLoadDuration}
        ></Image>
        <Typography variant="h5" color="text.secondary" gutterBottom
            sx={{paddingTop: 2, textAlign:'left'}}>
            What is Kenting known for?
        </Typography>
        
        <Typography variant="body1" color="text.secondary" gutterBottom
            sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
            Kenting is best known for beaches, hiking, and food. I've collected a list of local spots that I recommend from the area.
        </Typography>


        <Paper
        sx={{padding:2, margin:3, bgcolor:colorQuaternary}}>
            <Image
                bgColor={colorQuaternary}
                height='50vh' 
                fit='cover'
                src={"/content/aloha.jpeg"}
                duration={imageLoadDuration}
            ></Image>
            <Typography variant="h6" color="text.secondary" gutterBottom
                sx={{paddingTop: 2, textAlign:'left'}}>
                Best Coffee in Kenting
            </Typography>
            <Typography variant="body1" color="text.secondary" gutterBottom
                sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                Aloha has the best coffee in Kenting, and an excellent breakfast as well. The owners of the shop are kind people. The prices are a little high because the cafe is right by the best beach in Kenting, but being able to walk down the beach after an iced coffee is worth it.
            </Typography>
        </Paper>

        <Paper
        sx={{padding:2, margin:3, bgcolor:colorQuaternary}}>
            <Image
                bgColor={colorQuaternary}
                height='50vh' 
                fit='cover'
                src={"/content/beach3.png"}
                duration={imageLoadDuration}
            ></Image>
            <Typography variant="h6" color="text.secondary" gutterBottom
                sx={{paddingTop: 2, textAlign:'left'}}>
                Best Swimming Beach in Kenting
            </Typography>
            <Typography variant="body1" color="text.secondary" gutterBottom
                sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                There are a lot of great beaches in Kenting. Southbay Beach has a perfect blend of soft sand, mild waves, great shower/locker facilities, and a boardwalk full of great food & bars.
            </Typography>
        </Paper>

    </Paper>
    </Box>
  );
}