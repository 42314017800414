import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Image from 'mui-image'
import Home from './Tabs/Home';
import TheAnimals from './Tabs/TheAnimals';
import GettingAround from './Tabs/GettingAround';
import TheArea from './Tabs/TheArea';
import Lodging from './Tabs/Lodging';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TopTab() {
    const colorPrimary = '#617A55';
    const colorSecondary = '#A4D0A4';
    const colorTertiary = '#F7E1AE';
    const colorQuaternary = '#FFF8D6';
    const imageLoadDuration = 600;

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: colorQuaternary, height: '100%'}}>
        
      <AppBar position="fixed">
        <Tabs
            sx={{ bgcolor: colorPrimary}}
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
        >
          <Tab label="Home" {...a11yProps(0)} />
          <Tab label="Lodging" {...a11yProps(1)} />
          <Tab label="Getting Around" {...a11yProps(2)} />
          <Tab label="The Area" {...a11yProps(3)} />
          <Tab label="The Animals" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <Box sx={{ paddingTop: 5, height: '100%'}}>
        <Container maxWidth="md" sx={{height:'100%'}}>
            <TabPanel value={value} index={0} dir={theme.direction}>
                
                <Home></Home>

            </TabPanel>


            <TabPanel sx={{height:'90vh'}} value={value} index={1} dir={theme.direction}>
                <Lodging></Lodging>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
            
            <GettingAround></GettingAround>
                
            </TabPanel>


            <TabPanel value={value} index={3} dir={theme.direction}>
                <TheArea></TheArea>
            </TabPanel>


            <TabPanel value={value} index={4} dir={theme.direction}>
            
            <TheAnimals></TheAnimals>
                
            </TabPanel>
        </Container>
        </Box>
    </Box>
  );
}