import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Image from 'mui-image'

import TopTab from '../TopTab';

export default function GettingAround() {
    const colorPrimary = '#617A55';
    const colorSecondary = '#A4D0A4';
    const colorTertiary = '#F7E1AE';
    const colorQuaternary = '#FFF8D6';
    const imageLoadDuration = 600;

    const theme = useTheme();


  return (
    <Box>
        <Paper sx={{padding: 2, paddingBottom: 5, bgcolor:colorTertiary, textAlign:'left'}}>
                <Typography variant="h5" color="text.secondary" gutterBottom
                    sx={{textAlign:'left', paddingBottom: 2}}>
                    Getting Around Kenting:
                </Typography>

                <Paper
                    sx={{padding:2, margin:3, bgcolor:colorQuaternary}}>
                    <Image
                        bgColor={colorQuaternary}
                        height='40vh' 
                        fit='cover'
                        src={"/content/motorcycleStandin2.jpeg"}
                        duration={imageLoadDuration}
                    ></Image>
                    <Typography variant="h6" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2}}>
                        What is the best way to get around Kenting?
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2, textAlign:'left'}}>
                        The best way to get around Taiwan is on a motorbike. You can pick them up in Henchung, the city nearest to Kenting. I recommend [Name of Place] or <Link href="https://goo.gl/maps/Er7S7tgDLRk1T2wx7">Ku Cheng Motorcycle</Link>. The price as of 2023 is $500TWD per day.
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2, textAlign:'left'}}>
                        You will probably need an international motorcycle license. If you don't have one, I recommend going the next option.
                    </Typography>
                </Paper>

                <Paper
                    sx={{padding:2, margin:3, bgcolor:colorQuaternary}}>
                    <Image
                        bgColor={colorQuaternary}
                        height='40vh' 
                        fit='cover'
                        src={"/content/electricscooterSTANDIN.jpeg"}
                        duration={imageLoadDuration}
                    ></Image>
                    <Typography variant="h6" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2}}>
                        Can I get a motorbike in Kenting without a motorcycle license?
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2, textAlign:'left'}}>
                        The next best way to get around is on an electric motorbike. These don't require an international motorcycle license, and can get up to 40kmh. You can pick them up in Henchung at <Link href="https://goo.gl/maps/Er7S7tgDLRk1T2wx7">Ku Cheng Motorcycle</Link>.
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2, textAlign:'left'}}>
                        The price of renting as of 2023 is $600TWD per day.
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2, textAlign:'left'}}>
                        The drawback is that you will need to change out the battery at places that offer battery swapping. These spots are less common and only found in Henchung, as far as I know. So plan to be in Henchung every couple of days (about a 15 minute drive). When you visit, make sure to try the local cafes and restaurants, and visit the South Bay beach if you can.
                    </Typography>
                </Paper>

                <Paper
                    sx={{padding:2, margin:3, bgcolor:colorQuaternary}}>
                    <Image
                        bgColor={colorQuaternary}
                        height='40vh' 
                        fit='cover'
                        src={"/content/bus.webp"}
                        duration={imageLoadDuration}
                    ></Image>
                    <Typography variant="h6" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2}}>
                        Can I get around Kenting without renting a vehicle?
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom
                        sx={{paddingTop: 2, textAlign:'left'}}>
                        You can also get around by bus, which is the cheapest option but not very convenient in the countryside. As of 2023, the bus comes every half hour, but stops running at 6:00pm/18:00, so you may need to get a taxi if you stay out late. You can find the bus timetables <Link href="https://www.ptbus.com.tw/en/news_info/0/1/118?status=5">here</Link>.
                    </Typography>
                </Paper>
        </Paper>
    </Box>
  );
}