import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Image from 'mui-image'

import TopTab from '../TopTab';

export default function Home() {
    const colorPrimary = '#617A55';
    const colorSecondary = '#A4D0A4';
    const colorTertiary = '#F7E1AE';
    const colorQuaternary = '#FFF8D6';
    const imageLoadDuration = 600;

    const theme = useTheme();
    const [value, setValue] = React.useState(0);


  return (
    <Box>
<Typography variant="h4" color="text.secondary" gutterBottom
            sx={{textAlign:'left', paddingBottom: 2}}>
            Shady Tree Campground
        </Typography>

        <Paper sx={{padding: 2, paddingBottom: 2, bgcolor:colorTertiary, textAlign:'left'}}>

        

        <Paper
            sx={{padding:2, marginBottom:2, bgcolor:colorQuaternary}}>
            
            <Image
                bgColor={colorQuaternary}
                height='50vh' 
                fit='cover'
                src={"/content/sunset.jpeg"}
                duration={imageLoadDuration}
            ></Image>
            <Typography variant="body1" color="text.secondary" gutterBottom
                sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                Welcome to Shady Tree Campground. A home-away-from-home in the center of Kenting National Park. Pitch a tent or take up residence in one of our huts, and go explore the area.
            </Typography>
        </Paper>


        <Paper
        sx={{padding:2, marginBottom:2, bgcolor:colorQuaternary}}>
            <Image
                bgColor={colorQuaternary}
                height='50vh' 
                fit='cover'
                src={"/content/tents.jpeg"}
                duration={imageLoadDuration}
            ></Image>
            <Typography variant="body1" color="text.secondary" gutterBottom
                sx={{paddingTop: 2, textAlign:'left'}}>
                With the breeze off the river and the shade of the trees, your tent will stay nice and cool. The chickens keep the snakes and bugs away, but you'll likely see monkeys in the trees. If you stay up late, you may be lucky enough to see a small civet or crab-eating mongoose slinking through the camp.
            </Typography>
            <Typography variant="body1" color="text.secondary" gutterBottom
                sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                Electricity, hot showers, and an outdoor kitchen are all included.
            </Typography>
        </Paper>

        <Paper
        sx={{padding:2, marginBottom:2, bgcolor:colorQuaternary}}>
                <Image
                    bgColor={colorQuaternary}
                    height='50vh' 
                    fit='cover'
                    src={"/content/huts.jpeg"}
                    duration={imageLoadDuration}
                ></Image>
                <Typography variant="body1" color="text.secondary" gutterBottom
                    sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                    If you want a more comfortable stay, take up residence in one of our huts. A soft mattress, a good fan, and the sound of the rain on the roof are all good reasons.
                </Typography>
</Paper>

                <Paper
        sx={{padding:2, marginBottom:2, bgcolor:colorQuaternary}}>
                <Image
                    bgColor={colorQuaternary}
                    height='50vh' 
                    fit='cover'
                    src={"/content/beach2.jpg"}
                    duration={imageLoadDuration}
                ></Image>
                <Typography variant="body1" color="text.secondary" gutterBottom
                    sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                    Kenting has the best beaches in Taiwan. Enjoy soft sand and mild waves by the restaurants & cafes of the boardwalks. Or enjoy surfing at the rougher beaches.
                </Typography>
        </Paper>    

        <Paper
        sx={{padding:2, marginBottom:2, bgcolor:colorQuaternary}}>
                <Image
                    bgColor={colorQuaternary}
                    height='50vh' 
                    fit='cover'
                    src={"/content/forest.jpeg"}
                    duration={imageLoadDuration}
                ></Image>
                <Typography variant="body1" color="text.secondary" gutterBottom
                    sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                    The mountains of Kenting are covered in lush forest and refreshing waterfalls.
                </Typography>
        </Paper>

        <Paper
        sx={{padding:2, marginBottom:2, bgcolor:colorQuaternary}}>
                <Image
                    bgColor={colorQuaternary}
                    height='50vh' 
                    fit='cover'
                    src={"/content/thebar.jpeg"}
                    duration={imageLoadDuration}
                ></Image>
                <Typography variant="body1" color="text.secondary" gutterBottom
                    sx={{paddingTop: 2, paddingBottom:2, textAlign:'left'}}>
                    In the evening, come relax at the common area. Couches, hammocks, and a self-service bar all make it a good place to watch the sun set or listen to the rain against the roof.
                </Typography>
        </Paper>
        </Paper>
    </Box>
  );
}